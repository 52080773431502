import React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO, Hero, ContactSection } from '../components';
import { useGraphQL } from '../hooks';

function SuccessPage() {
  return (
    <Layout>
      <SEO title="Success" />
      <HeroSection />
      <ContactSection />
    </Layout>
  );
}

function HeroSection() {
  const { successNotFoundHero } = useGraphQL();

  return (
    <Hero image={successNotFoundHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-white heading-1">Success</h1>
        <p className="text-lg text-white uppercase">
          Thank you, our team will get back to you shortly
        </p>
        <Link to="/" className="text-white button">
          Return Home
        </Link>
      </div>
    </Hero>
  );
}

export default SuccessPage;
